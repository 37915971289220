<script lang="ts" setup>
const client = useSupabaseAuthClient()
const user = useSupabaseUser()
const config = useRuntimeConfig()
const url = computed(() => config.public.appUrl)

const loginWithGoogle = async () => {
    const { error } = await client.auth.signInWithOAuth({
        provider: 'google',
        options: { redirectTo: url.value },
    })
}

watchEffect(() => {
    if (user.value) {
        navigateTo('/')
    }
})
</script>

<template>
    <div flex items-center justify-center p-8 mb-auto>
        <cc-button primary data-test-id="login-button" @click="loginWithGoogle">
            <div i-ri-google-fill mr-2 /> Logar com Google
        </cc-button>
    </div>
</template>
